<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.conclusions_history") }}
        </h5>

        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
      </div>
    </div>
    <table style="background: #fff" class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.patient_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.doctor_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.service_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.updated_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.order_id"
              :placeholder="columns.id.title"></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input size="mini" v-model="filterForm.patient_id" :placeholder="columns.patient_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.doctor_id.show">
            <el-input size="mini" v-model="filterForm.doctor_id" :placeholder="columns.doctor_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.service_id.show">
            <el-input size="mini" v-model="filterForm.service_id" :placeholder="columns.service_id.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.order_id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              item.patient
              ? item.patient.surname + " " + item.patient.first_name
              : ""
            }}
          </td>
          <td v-if="columns.doctor_id.show">
            {{
              item.doctor
              ? (item.doctor.surname ? item.doctor.surname : "") +
              " " +
              item.doctor.name
              : ""
            }}
          </td>
          <td v-if="columns.service_id.show">
            <span v-if="item.order">
              <span v-for="(service, index) in item.order.services" :key="index">
                {{ service.code }}
              </span>
            </span>
            <span v-else> {{ "-" }} </span>
          </td>
          <td v-if="columns.updated_at.show">
            {{ item.updated_at }}
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <!-- v-can="'printCenter.show'" -->
            <el-button round @click="show(item)" size="mini" type="primary" icon="el-icon-view">{{ $t("message.show_0")
            }}</el-button>
            <!-- <crm-setting-dropdown :model="item" name="conclusions" :actions="actions" @edit="edit" @delete="destroy">
                        </crm-setting-dropdown> -->
          </td>
        </tr>
      </transition-group>
    </table>
    <!-- <el-drawer title="Новый тег" :visible.sync="drawerCreate" size="60%" :drawer="drawerCreate" @close="reloadIfChanged" >
            <div>
                <crm-create @c-close="closeDrawer"></crm-create>
            </div>
        </el-drawer> -->
    <el-drawer title="Изменить тег" :visible.sync="drawerShow" size="70%" :wrapperClosable="false" :drawer="drawerShow"
      @open="reopenShow = true" @close="reopenShow = false" @closed="emptyModel">
      <div>
        <print-form :reloadModel="reopenShow" @open="reopenShow = true" @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"></print-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import CrmCreate from "./components/crm-create";
import PrintForm from "./components/print-form";
import list from "@/utils/mixins/list";
import axios from "axios";
export default {
  mixins: [list],
  name: "conclusionsList",
  components: {
    PrintForm,
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      reopenShow: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
    };
  },
  computed: {
    ...mapGetters({
      list: "conclusions/inventory",
      columns: "conclusions/columns",
      token: "auth/token",
      pagination: "conclusions/pagination",
      filter: "conclusions/filter",
      sort: "conclusions/sort",
      states: "states/list",
      model: "orders/relation_model",
      user_id: "auth/id",
    }),
  },
  async created() { },
  async mounted() { },
  watch: {
    columns: {
      handler: function () { },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "conclusions/inventory",
      updateSort: "conclusions/updateSort",
      updateFilter: "conclusions/updateFilter",
      updateColumn: "conclusions/updateColumn",
      updatePagination: "conclusions/updatePagination",
      showModel: "conclusions/show",
      empty: "conclusions/empty",
      delete: "conclusions/destroy",
      refreshData: "conclusions/refreshData",
    }),
    fetchData() {
      // this.updatePagination({per_page:500});
      let patient = this.model.patient ? this.model.patient : "";
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        patient: true,
        service: true,
        print_center: true,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    // async edit(model){
    //     await this.editModel(model.id)
    //         .then(async (res) => {
    //             this.drawerUpdate = true;
    //         })
    //         .catch(err => {
    //         });
    // },
    async show(model) {
      axios({
        url: this.backUrl + "/get_file_type/" + model.order_id,
        method: "GET",
      }).then((res) => {
        if (res.data == "docx") {
          var name =
            model.order_id +
            " " +
            model.patient.surname +
            " " +
            model.patient.first_name;
          var link = document.createElement("a");
          link.setAttribute(
            "href",
            this.backUrl +
            "/file_orientation?order_id=" +
            model.order_id +
            "&name=" +
            name +
            "&user=" +
            this.token +
            "&status=show" +
            "&user_type=laborant"
          );
          link.setAttribute("target", "_blank");
          link.click();
          return;
        }
      });

      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },

    // controlExcelData(){
    //     this.excel_fields = {};
    //     for (let key in this.columns){
    //         if (this.columns.hasOwnProperty(key)){
    //             let column = this.columns[key];
    //             if (column.show && column.column !== 'settings'){
    //                 this.excel_fields[column.title] = column.column;
    //             }
    //         }
    //     }
    // },
    // destroy(model){
    //     this.delete(model.id)
    //         .then(res => {
    //             this.$notify({
    //   title: 'Успешно',
    //   type: "success",
    //   offset: 130,
    //   message: res.message
    // });
    //             // this.fetchData()
    //         })
    //         .catch(err => {
    //            console.log(err)
    //         })
    // },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
